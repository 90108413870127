import $ from "jquery";
import {Controller} from "@hotwired/stimulus";

const SINGULAR_SHIPMENTS = ['bulk', 'genco']

export default class extends Controller {
  static targets = ["box", "header", "type", "action", "totalGrossWeight"];


  async connect() {
    this.clean_state = true;
    this.hideTotalGrossWeight();

    await $(this.typeTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    if (this.typeTarget.dataset.editable === 'false') {
      $(this.typeTarget).prop("disabled", true);
      this.adjustButtons(this.typeValue());
    }
    const shipmentType = this.typeValue();

    if (shipmentType === 'Container') {
      this.showTotalGrossWeight();
    }

    this.assocButtons().forEach(link => {
      if (this.isSingularAssociation(shipmentType)) {
        link.classList.add('d-none');
      }
    });
  }

  disconnect() {
    this.removeAllAssocRows();
    this.hideAllAddAssocButtons();
  }

  typeValue() {
    return $(this.typeTarget).select2('data')[0].element.value;
  }

  assocRows() {
    return this.boxTarget.querySelectorAll('.assoc-row');
  }

  assocButtons() {
    return this.boxTarget.querySelectorAll('.add_fields');
  }

  removeAllAssocRows() {
    this.assocRows().forEach(row => row.remove());
  }

  removeIrrelevantAssocRows(shipmentType) {
    this.assocRows().forEach(row => {
      if (!row.dataset.association.startsWith(shipmentType.toLowerCase())) row.remove();
    });
  }

  hideAllAddAssocButtons() {
    this.assocButtons().forEach(link => link.classList.add('d-none'));
  }

  hideAssocButton(element) {
    element.classList.add('d-none');
  }

  showAssocButton(element) {
    element.classList.remove('d-none');
  }

  isSingularAssociation(assocName) {
    return SINGULAR_SHIPMENTS.some(shipment => assocName.toLowerCase().startsWith(shipment));
  }

  setHeader(assocName) {
    const isEmpty = assocName === '';
    if (isEmpty) {
      this.headerTarget.innerText = '';
      return;
    }

    const isSingular = this.isSingularAssociation(assocName);
    this.headerTarget.innerText = isSingular ? assocName : `${assocName}s`;
  }

  showBox(shipmentType) {
    this.setHeader(shipmentType);
    this.boxTarget.classList.remove('d-none');
  }

  isFormDirty() {
    return this.clean_state === false;
  }

  makeFormDirty() {
    if (this.isFormDirty()) this.removeAllAssocRows();
    this.clean_state = false;
  }

  clearEverything() {
    this.removeAllAssocRows();
    this.hideAllAddAssocButtons();
    this.setHeader('');
  }

  adjustButtons(shipmentType) {
    this.assocButtons().forEach(link => {
      link.dataset.assoctype.startsWith(shipmentType.toLowerCase()) ? this.showAssocButton(link) : this.hideAssocButton(link);
    })
  }

  setAssociation() {
    const shipmentType = this.typeValue();

    if (shipmentType === '') {
      this.clearEverything();
      return;
    }

    this.removeIrrelevantAssocRows(shipmentType);
    this.showBox(shipmentType);
    this.makeFormDirty();

    if (this.isSingularAssociation(shipmentType)) {
      this.assocButtons().forEach(link => {
        if (link.dataset.assoctype.startsWith(shipmentType.toLowerCase())) {
          link.click();
        }
      });
      this.hideAllAddAssocButtons();
      return;
    }

    this.adjustButtons(shipmentType);
  }

  removeAssocRow(event) {
    event.currentTarget.closest('.assoc-row').nextElementSibling.value = "true";
    event.currentTarget.closest('.assoc-row').remove();
    return event.preventDefault();
  }

  shipmentTypeChanged(event) {
    if (event.target.value === "Container") {
      this.showTotalGrossWeight()
    } else {
      this.hideTotalGrossWeight()
    }
  }

  showTotalGrossWeight() {
    this.totalGrossWeightTarget.classList.remove('d-none');
  }

  hideTotalGrossWeight() {
    this.totalGrossWeightTarget.classList.add('d-none');
  }
}
