import FormUtils from '../helpers/form-utils';
import { Controller } from '@hotwired/stimulus';

const getErrorNode = text => {
  const errorNode = document.createElement('div');
  errorNode.classList.add('invalid-feedback');
  errorNode.id = 'error-node';
  errorNode.innerText = text;
  return errorNode;
};

export default class FormController extends Controller {
  isSubmitted = false;
  validationRules = {};
  values = {};

  constructor(context, validationRules, optionalRuleIds = []) {
    super(context);

    if (optionalRuleIds.length) {
      optionalRuleIds.forEach(ruleId => {
        if (!this.element.querySelector(`#${ruleId}`)) {
          delete validationRules[ruleId];
        }
      });
    }

    this.validationRules = validationRules;

    try {
      this.setPhoneValidationEvents();
    } catch {}

    try {
      Object.entries(this.validationRules).forEach(
        ([id, { isValid, errorMessage }]) => {
          const input = document.getElementById(id);

          if (input) {
            if (input.type === 'file') {
              input.addEventListener(FormUtils.fileCountChangeEvent.type, e => {
                this.values[id] = e.target.dataset.fileCount;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              });
            } else if (input.type === 'checkbox') {
              input.onchange = e => {
                this.values[id] = e.target.checked;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              };
            } else {
              input.oninput = e => {
                this.values[id] = e.target.value;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              };
            }
          } else {
            console.error(`Can't find input with id: ${id}`);
          }
        },
      );
    } catch {
      console.error("Can't initialize form controller");
    }
  }

  validateForm() {
    const validateStatuses = Object.entries(this.validationRules).map(
      ([id, { isValid, errorMessage }]) => {
        return this.validateInput(id, isValid, errorMessage);
      },
    );
    this.isSubmitted = true;

    return validateStatuses.every(isValid => isValid);
  }

  validateInput(id, isValid, errorMessage) {
    const input = document.getElementById(id);
    const submitButton = this.#getForm().querySelector('input[type="submit"]');

    if (!isValid(input, this.values)) {
      if (!input.classList.contains('is-invalid')) {
        const errorNode = getErrorNode(errorMessage);
        input.parentNode.appendChild(errorNode);
        input.classList.add('is-invalid');
        document
          .getElementById(id)
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return false;
    }

    const errorNode = input.parentNode.querySelector('#error-node');
    errorNode && errorNode.remove();
    input.classList.remove('is-invalid');
    submitButton.removeAttribute('data-disable-with');
    submitButton.disabled = false;
    return true;
  }

  submit(e) {
    e.preventDefault();

    const submitButton = this.#getForm().querySelector('input[type="submit"]');

    let submitButtonText;
    if (submitButton) {
      submitButtonText = `${submitButton.value}`;

      submitButton.value = 'Please wait...';
      submitButton.disabled = true;
    }

    const isFormValid = this.validateForm();
    if (isFormValid) {
      this.#getForm().submit();
      // Wait 1 second before re-enabling submit button
      setTimeout(() => {
        if (submitButton) {
          submitButton.value = submitButtonText;
          submitButton.disabled = false;
        }
      }, 1000);
    } else {
      if (submitButton) {
        submitButton.value = submitButtonText;
        submitButton.disabled = false;
      }
    }
  }

  setPhoneValidationEvents() {
    const phoneInputs = document.querySelectorAll(
      '[data-validate-phone="true"]',
    );

    if (phoneInputs) {
      phoneInputs.forEach(phoneInput => {
        phoneInput.addEventListener('blur', this.formatPhone);
      });
    }
  }

  formatPhone(e) {
    const phoneInput = e.target;

    const { isValid, phoneNumber } = FormUtils.checkPhoneNumber(
      phoneInput.value,
    );

    if (isValid) {
      phoneInput.value = phoneNumber;
    }
  }

  #getForm() {
    return this.element.querySelector('form');
  }
}
