import $ from 'jquery';
import {Controller} from "@hotwired/stimulus";

export default class ConfirmationController extends Controller {
  static values = {
    message: String,
  }

  confirm(event) {
    const confirmed = confirm(this.messageValue);

    if (!confirmed) {
      event.preventDefault();
    }
  }
}