import FormUtils from '../helpers/form-utils';
import FormController from './form_controller';
import { icon } from "./existence_checker_controller";

const validationRules = {
  entry_shipper_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_shipper_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_shipper_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_consignee_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Consignee name can't be blank",
  },
  // entry_consignee_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_consignee_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_notify_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_notify_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_notify_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_bl_number: {
    isValid: input => !!input.value,
    errorMessage: "Bl number can't be blank",
  },
  entry_voyage_no: {
    isValid: input => !!input.value,
    errorMessage: "Voyage no can't be blank",
  },
  entry_carrier_id: {
    isValid: input => !!input.value,
    errorMessage: "Carrier can't be blank",
  },
  entry_vessel_id: {
    isValid: input => !!input.value,
    errorMessage: "Vessel can't be blank",
  },
  entry_etd: {
    isValid: input => !!input.value,
    errorMessage: "Etd can't be blank",
  },
  entry_eta: {
    isValid: input => !!input.value,
    errorMessage: "Eta can't be blank",
  },
  entry_country: {
    isValid: input => true,
  },
  entry_port_of_loading_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of loading can't be blank",
  },
  entry_port_of_discharge_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of discharge can't be blank",
  },
  entry_incoterm: {
    isValid: input => !!input.value,
    errorMessage: "Incoterm can't be blank",
  },
  entry_description_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "Description of goods can't be blank",
  },
  entry_cost_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "FOB value can't be blank",
  },
  entry_freight_value: {
    isValid: input => !!input.value,
    errorMessage: "Freight value can't be blank",
  },
  entry_insurance_value: {
    isValid: input => !!input.value,
    errorMessage: "Insurance value can't be blank",
  },
  entry_other_charges: {
    isValid: input => !!input.value,
    errorMessage: "Other charges can't be blank",
  },
  entry_volume: {
    isValid: input => !!input.value,
    errorMessage: "Volume can't be blank",
  },
  entry_shipment_type: {
    isValid: input => !!input.value,
    errorMessage: "Shipment Type can't be blank",
  },
  entry_bill_of_loading_doc: {
    isValid: input => true,
  },
  entry_commercial_invoice_doc: {
    isValid: input => true,
  },
  entry_customs_decleration_doc: {
    isValid: input => true,
  },
};

export default class NewEntryFormController extends FormController {
  static targets = ['costOfGoods', 'warning', 'hscode', 'nature', 'quantity'];

  constructor(context) {
    super(context, validationRules);
  }

  connect() {
    super.connect();
    // Listen for the custom event
    this.element.addEventListener('validateCostOfGoods', this.handleCostOfGoodsValidation.bind(this));
  }

  handleCostOfGoodsValidation(event) {
    const { hsCode, nature, quantity } = event.detail;
    if (hsCode === "220710" && nature === "Ton") {
      const costOfGoodsStr = this.costOfGoodsTarget.value.replace(/,/g, '') || 0;
      const costOfGoods = parseFloat(costOfGoodsStr);
      console.log("cost of goods:", costOfGoods);
      const costPerTon = quantity > 0 ? costOfGoods / quantity : 0;

      console.log("cost per ton:", costPerTon);

      if (costPerTon < 400 || costPerTon > 800) {
        // Show validation warning/error
        console.log("Cost per ton must be between 400-800.");
        this.showWarning();
      } else {
        // Clear any previous validation warning/error
        console.log("Validation passed.");
        this.hideWarning();
      }
    }
  }

  showWarning() {
    this.warningTarget.innerHTML = `<div class="d-flex align-items-center text-danger">${icon} Unusual weight-to-value ratio detected.</div>`;
    this.warningTarget.classList.remove('d-none');
  }

  hideWarning() {
    this.warningTarget.classList.add('d-none');
  }

  onCostChange(event) {
    console.log("Cost of goods has changed.", event.target.value);

    let hsCode = this.hscodeTarget.textContent.slice(17, 23);
    let nature = this.natureTarget.value;
    let quantity = parseFloat(this.quantityTarget.value || 0);

    const detail = { hsCode, nature, quantity };
    this.handleCostOfGoodsValidation({ detail });
  }
}
