import $ from 'jquery';
import Rails from "@rails/ujs";
import {Controller} from "@hotwired/stimulus"

export default class DraftController extends Controller {
  static targets = [
    'draftId',
    'shipperName',
    'shipperPhone',
    'shipperAddress',
    'consigneeName',
    'consigneePhone',
    'consigneeAddress',
    'notifyName',
    'notifyPhone',
    'notifyAddress',
    'blNumber',
    'voyageNo',
    'carrier',
    'vessel',
    'eta',
    'etd',
    'pol',
    'pod',
    'incoterm',
    'currencyOfGoods',
    'costOfGoods',
    'currencyOfFreight',
    'freightValue',
    'currencyOfInsurance',
    'insuranceValue',
    'volume',
    'descriptionOfGoods',
  ];

  connect() {
    $('#entry_port_of_loading_id').on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    $('#entry_port_of_discharge_id').on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    $('#entry_incoterm').on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });
  }

  inputChanged() {
    this.data.set('form-dirty', 'true');
  }

  isFormDirty() {
    return this.data.get('form-dirty') === 'true';
  }

  makeFormClean() {
    this.data.set('form-dirty', 'false');
  }

  async saveDraft() {
    if (!this.isFormDirty()) {
      return;
    }

    let draft_id = this.hasDraftIdTarget ? this.draftIdTarget.value : null;
    let draft_entry = {
      draft_id: draft_id,
      shipper_name: this.shipperNameTarget.value,
      shipper_phone: this.shipperPhoneTarget.value,
      shipper_address: this.shipperAddressTarget.value,
      consignee_name: this.consigneeNameTarget.value,
      consignee_phone: this.consigneePhoneTarget.value,
      consignee_address: this.consigneeAddressTarget.value,
      notify_name: this.notifyNameTarget.value,
      notify_phone: this.notifyPhoneTarget.value,
      notify_address: this.notifyAddressTarget.value,
      bl_number: this.blNumberTarget.value,
      voyage_no: this.voyageNoTarget.value,
      carrier: this.carrierTarget.value,
      vessel: this.vesselTarget.value,
      eta: this.etaTarget.value,
      etd: this.etdTarget.value,
      pol: this.polTarget.value,
      pod: this.podTarget.value,
      incoterm: this.incotermTarget.value,
      currency_of_goods: this.currencyOfGoodsTarget.value,
      cost_of_goods: this.costOfGoodsTarget.value,
      currency_of_freight: this.currencyOfFreightTarget.value,
      freight_value: this.freightValueTarget.value,
      currency_of_insurance: this.currencyOfInsuranceTarget.value,
      insurance_value: this.insuranceValueTarget.value,
      volume: this.volumeTarget.value,
      description_of_goods: this.descriptionOfGoodsTarget.value,
    };

    const request_method = draft_id ? 'PATCH' : 'POST';
    const request_url = draft_id ? `/drafts/${draft_id}` : '/drafts';

    await fetch(request_url, {
      method: request_method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF_Token': Rails.csrfToken(),
      },
      body: JSON.stringify({ draft: draft_entry }),
    })
  }
}
