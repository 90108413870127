import {Controller} from "@hotwired/stimulus"
import select2 from 'select2'
import $ from 'jquery';

export default class extends Controller {
  static targets = ['select' ,'hscode', 'nature', 'quantity']

  initialize() {
    select2();
  }

  connect() {
    $('.select2-custom').each((_, select) => {
      if (
        select.tagName === 'SELECT' &&
        select.id.startsWith('entry_goods_attributes') &&
        select.id.endsWith('_hscode_id')
      ) {
        $(select).select2({ width: '100%' });
      }
    });

    setTimeout(() => {
      this.ajaxifiedHscodeSelect();
    }, 1000);
  }

  disconnect() {
    $(this.selectTarget).select2("destroy");
  }

  onChange() {
    // Extracting first six characters of the HS code
    let hsCode = this.hscodeTarget.textContent.slice(17, 23);
    let nature = this.natureTarget.value;
    let quantity = parseFloat(this.quantityTarget.value || 0);

    // Dispatch the custom event with details necessary for validation
    this.element.dispatchEvent(new CustomEvent('validateCostOfGoods', {
      bubbles: true,
      detail: { hsCode, nature, quantity }
    }));
  }

  ajaxifiedHscodeSelect () {
    $(this.selectTarget).select2({
      minimumInputLength: 3,
      ajax: {
        dataType: 'json',
        delay: 250,
        url: '/hscodes',
        data: function (params) {
          return {
            query: params.term
          }
        },
        processResults: function (data) {
          return {
            results: data.map(function (item) {
              return { id: item.id, text: item.hs_code + ' - ' + item.description }
            })
          }
        }
      }
    });
  }
}
